export default {
  pages: {
    editMHAStatus: {
      currentMHAStatus: "Current MHA status",
      updateMHAStatus: "Update MHA Status",
      successMessage: "Patient MHA status updated",
      errorMessage: "Failed to update patient MHA status",
      unknown: "Unknown",
      section5_4: "Section 5(4)",
      section5_2: "Section 5(2)",
      section4: "Section 4",
      section2: "Section 2",
      section3: "Section 3",
      cto: "CTO",
      notDetained: "Not detained",
      startDateTime: "Start date and time of detention",
      endDateTime: "End date and time of detention",
      startDate: "Start date of detention",
      endDate: "End date of detention",
      hospital: "Hospital",
      updateStatusBtn: "Update status",
      discardBtn: "Back to patient",
      hasRenewalCTO: "Has the original CTO period been extended?",
      hasRenewalSection3: "Has the original Section 3 period been renewed?",
      renewals: "Renewals",
      extensions: "Extensions",
      originalCTOStartDate: "Original CTO start date",
      originalCTOExpiryDate: "Original CTO end date",
      originalSection3StartDate: "Original Section 3 start date",
      originalSection3ExpiryDate: "Original Section 3 end date",
      ctoStartDate: "Current CTO start date",
      ctoExpiryDate: "Current CTO end date",
      section3StartDate: "Current Section 3 start date",
      section3ExpiryDate: "Current Section 3 end date",
      notDetainedReason: "Not detained reason",
      dischargedSection23: "Discharged Section 23",
      dischargedHoldingPowers: "Discharged from holding powers",
      dischargedTribunal: "Discharged by Tribunal/Nearest Relative",
      dateTimeDischarge: "Date and time of discharge",
      previousHospital: "Previous Hospital",
      hospitalToolTip: "Responsible hospital for the patient’s care",
      scrutinyWarningMessage:
        "Please review information and make sure it is accurate and reflecting the care for this patient before marking scrutiny for this assessment as complete.",
      unknownIncomplete: "Unknown (Incomplete)",
      unknownExpired: "Unknown (Expired)",
      continue: "continue",
      confirmAction:
        "You are about to update MHA Status for this patient. Please make sure you have entered the correct information.",
      success: "Success",
      successModalBody: "MHA Status details have successfully been updated",
      scrutinyConfirmModalAdmission:
        "You are about to mark administrative scrutiny for this assessment as complete. Please note that you cannot add a file note or send a section 15 amend request for this assessment once you confirm. The MHA Status can still be edited once you confirm.",
      scrutinyConfirmModalStandalone:
        "You are about to mark administrative scrutiny for this assessment as complete. Please note that you cannot add a file note to this form once you confirm. The MHA Status can still be edited once you confirm.",
      editMHAStatus: "Edit MHA Status",
      other: "Other",
      pendingCTO:
        "The start date is set in the future. Please ensure the patient is currently on a Section 3. The MHA Status of this patient will update to Section 3 (CTO pending) and automatically update to CTO when the latest start date is met.",
      renewalPendingCTO:
        "The start date is set in the future. The MHA Status of this patient will update to CTO (Extension pending) and automatically revert back to CTO when the latest start date is met.",
      renewalPendingSection3:
        "The start date is set in the future. The MHA Status of this patient will update to Section 3 (Renewal pending) and automatically revert back to Section 3 when the latest start date is met.",
    },
    common: {
      signature: {
        label: "Signature",
        checkboxLabel: "Use my existing signature",
        confirmButtonLabel: "I AM HAPPY WITH MY SIGNATURE",
        clearButtonLabel: "CLEAR",
        drawSignatureLabel: "Click to draw",
        uploadLabelErrorImg: "Only {{ validMimeTypes }} files are allowed",
        uploadLabelErrorFileSize1MB: "File size must be less than 1MB",
        uploadLabelErrorFileSize5MB: "File size must be less than 5MB",
      },
    },
    contextSelector: {
      subtitleText: "Team Selector",
      saveAsDefaultTeam: "Save as default team",
      typeaheadLabel: "Team",
      typeaheadPlaceholder: "Start typing to search for role and team",
      logOut: "Log out",
      continue: "Continue",
      welcome: "Welcome",
      chooseContext: "Please choose who you are working for today",
      setupAccount:
        "In order to proceed you need to join a team/organisation or set up your own personal organisation",
    },
    caseload: {
      title: "Caseload",
      teamCaseload: "Team Caseload",
      tabLabelUserCaseload: "Drafts",
      tabLabelArchive: "Archive",
      nhsNumber: "NHS number",
      searchLabel: "Search for patient in your caseload",
      searchLabelTeam: "Search for patient in your team",
      dateOfBirth: "Date of birth",
      myCaseload: "MY CASELOAD",
      guestUserBanner:
        "Archive history is limited to one month for guest users",
      noResultsSearchBanner:
        "You have no forms in your caseload. Create patient to start new form.",
      noResultsSearchBannerTeam:
        "No patients have been found in your team caseload with these details. Perform a new search with different data or search patients in your organisation.",
      noResultsWhenSearchBanner:
        "No patients have been found in your caseload with these details. Perform a new search with different data or create a new patient.",
      noResultsWhenSearchBannerTeam:
        "You have no forms in your team caseload. Search patient to start new form.",
      popupDialog: {
        popArchiveTitle: "Archiving in progress form",
        popupWarningText:
          "This item is still in progress. Are you sure you want to move this item to your archive? ",
      },
      table: {
        headingOne: "Patient Name",
        headingTwo: "DOB",
        headingThree: "NHS Number",
        headingFour: "Form",
        headingFive: "Summary",
        headingSix: "Claimed",
        headingSeven: "Actions",
      },
    },
    formDraftPreviewPage: {
      popupDialog: {
        warningMessage:
          "Please confirm that you have read the form, are happy with the information, and are willing to proceed to the next step.",
        warningMessageAmends:
          "This amend is in effect from the time and date of the original signature. You do not need to re-sign the form.",
        formTitleSubtitleTextAmends: "Confirm amend",
        formTitleSubtitleText: "Confirm Completion",
      },
    },
    formDraftCompletionPage: {
      patientDetailsEdited: "Patient details edited",
      patientDetailsEditedBanner:
        "Patient details on this form have been edited. Please ensure the correct details are entered on this form before signing.",
    },
    formDraftSignPage: {
      typeaheadLabel: "Enter email address of recipient",
      formSubText: "Invite others to collaborate on this form",
      medicalRecommendationsLabel:
        "Please select if one or more medical recommendations were not done on Thalamos",
      dialog: {
        content:
          "Please confirm that you have read the form, are happy with the information, and are willing to proceed to the next step.",
      },
      supportingDocuments: {
        patientMergeWarningMessage:
          "You are about to select a form that has been merged from another patient record, patient name may not be the same. Please check the details carefully before signing. ",
        validationSuccess: {
          messageTitle: "Successful selection",
          messageInfo:
            "It is still your obligation to scrutinise the medical recommendation(s) and their validity.",
        },
        validationError: {
          messageTitle: "Unsuccessful selection",
        },
      },
    },
    formPage: {
      patientDetailsOutdatedAdmissions:
        "The patient details have been updated in Thalamos since the first admission form or form part was signed. This may invalidate the admission - please check the details carefully before signing.",
      patientDetailsOutdated:
        "The patient details have been updated in Thalamos since this form was first signed. This may invalidate the form - please check the details carefully before signing",
      formTitle: "Form progress",
      startForm: "Start Form",
      requestForm: "Request Form",
      application: "Application",
      recordDetentionFormTitle: "Start Record of Detention Form",
      section4ActiveBanner:
        "A patient admitted under section 4 may be detained only for a maximum of 72 hours unless a second medical recommendation is provided and part 2 of form H3 is completed. The second medical recommendation must be a single section 2 form. Continue part 2 to record the time and date the second medical recommendation was received.",
      section4ExpiredBannerTitle: "Section 4 expired ",
      section4ExpiredBannerBody:
        "A patient admitted under section 4 may be detained only for a maximum of 72 hours unless a second medical recommendation is provided and part 2 of Form H3 is completed. Part 2 has not been completed within the 72 hour period. Everyone involved in the assessment should discuss how to proceed.",
      unassigned: "Unassigned",
      noSignaturesYet: "None Yet",
      previewPdf: "preview PDF",
      viewPdf: "view PDF",
      status: "Status",
      preview: "Preview",
      signatories: "Signatories",
      part: "Part",
      userName: "User Name",
      teamName: "Team Name",
      organisationName: "Organisation Name",
      signatureDate: "Signature Date",
      typeaheadLabel: "Add new collaborator",
      participants: "Participants",
      notificationStatusNotViewed: "Not Viewed",
      notificationStatusViewed: "Viewed",
      linkedForms: "Linked Forms",
      formType: "Linked Form Type",
      nameDoesNotMatchPreviousFormPart:
        "Name entered on form is different to the previous part of this form you signed",
      addressDoesNotMatchPreviousFormPart:
        "Address entered on form is different to the previous part of this form you signed",
      postalCodeDoesNotMatchPreviousFormPart:
        "Postcode entered on form is different to the previous part of this form you signed",
      nameDoesNotMatchProfile:
        "Name entered on form is different to the name saved in your profile",
      addressDoesNotMatchProfile:
        "Address entered on form is different to the address saved in your profile",
      postalCodeDoesNotMatchProfile:
        "Postcode entered on form is different to the postcode saved in your profile",
      bannerInfoArchiveAdmission:
        "This admission is in your team's archive. It has been confirmed that a medical recommendation will not be made for this patient. Please open the activity log to view assessment details. Unarchive to proceed working on the possible admission for this patient if circumstances have changed.",
      bannerInfoArchiveAdmissionNoApp:
        "It has been confirmed that an application will not be made for this patient. Please open the activity log to view assessment details. You can click reopen to proceed working on the possible admission for this patient if circumstances have changed.",
      bannerInfoArchiveAdmissionNoAppNonAmhp:
        "This admission is in your team's archive. It has been confirmed that an application will not be made for this patient. Please open the activity log to view assessment details. If you feel you should proceed working on the possible admission for this patient please contact one of the approved mental health professionals outside of Thalamos and ask them to reopen the admission.",
      bannerInfoArchive:
        "This form is in your team's archive. Unarchive it to work on it",
      quickItemTextSection:
        "Send forms to another team and request the record of detention in hospital to be completed",
      quickItemTextShare: "Send form(s) to another team to work on it",
      bannerWarningNextPart:
        "This assignee cannot work on the next part of this form. This might be the right thing to do (e.g. if you want them to help you co-ordinate the form) but please double check you've assigned to the right person/team",
      checkBoxLabelArchive:
        "Archive this form from your caseload when sharing?",
      formTitleLinked: "This form will be linked to the current form",
      checkBoxLabelStartWork: "Start work on this form yourself",
      finaliseForm: "Scrutinise this form",
      typographyTextSent: "Sent",
      headingTextReceiver: "Receiver",
      teamNameBannerText: "You are working for",
      admissionStandardTitle: "Start Medical Recommendation Form",
      applicationFormTitle: "Application Form",
      autoSaveStatusDraft: {
        saveInProgress: "Saving {{ type }}",
        saveSuccess: "{{ type }} Saved",
        saveError: "Error saving {{ type }}",
      },
      autoSaveStatusProfile: {
        saveInProgress: "Saving profile",
        saveSuccess: "Profile Saved",
        saveError: "Error saving profile",
      },
      formRowCategory: {
        recordofDetentionTitle: "Record of Detention",
        h3Subtitle: "Form H3 -",
        medicalRecommendationTitle: "Medical Recommendation",
        multiSections: "Section 2, 3 and 4",
        requestMedicalRecommendationTitle: "Request Medical Recommendation",
        requestApplicationTitle: "Request Application",
      },
      participantsList: {
        popupDialog: {
          title: "Invite declined ",
          admissionTitleNoMedRec: "Medical recommendation not made",
          admissionTitleNoApp: "Application not made",
          admissionReadOnlyContentTitleMedRec:
            "Reason why the medical recommendation was not made",
          admissionReadOnlyContentTitleNoApp:
            "Reason why the application was not made",
          readOnlyContentTitle: "Reason why the invite was declined",
        },
      },
    },
    organisationHomePage: {
      organisation: "Organisation",
    },
    patientHome: {
      title: "Patient",
      newForm: "New Form",
      admissionFormTitle: "Admission Forms",
      section: "Section",
      connectingToExternalSystem: "Connecting to external system..",
      returnToPatientHome:
        "Not loading? Click here to continue to the patient home page without synchronising demographics.",
      viewLess: "View less",
      viewMore: "View more",
      launchForm: {
        confirmAction: "Confirm Action",
        startNew: "Start New Form",
        requestFormCompletion: "Invite another user or team to start new form",
        whatDoYouWantToDo: "What do you want to do?",
        selectForm: "Select Form",
        searchTeamUser: "Search for Team/User",
        inviteUser: "Invite",
        startForm: "Start Form",
        close: "Close",
        admissionProgressTitle: "Admission already in progress",
        admissionWarningBannerTitle:
          "An admission form has already been started for this patient. Continue working on this admission or start a new one",
        popupDialogTitle: "Form already in progress",
        formProgressWarningMessage:
          "has already been started for this patient. Continue working on the form below or start a new one.",
        radioSelectorWarningMessage:
          "Please select a form and click continue or start new form",
        inviteSuccessfullySentLabel: "Invite successfully sent",
        inviteSuccessfullySentMessage: "Invite successfully sent",
        admissionValidityInstructions:
          "The section 2 and 3 application is valid for 14 days from the last time the doctor examined the patient. The emergency application is valid for 24 hours from the last time the doctor examined the patient or when the application was made. Please make sure to check the validity of the admission forms before proceeding. If you are unsure, please speak to your mental health act team.",
        detentionApplicationSelectionPrompt:
          "Please select the application made for purpose of the detention",
      },
    },
    patientEditCreate: {
      createError: "An error occurred when creating the patient",
      createSuccess: "Patient created successfully",
      updateError: "Some kind of error occurred when updating the patient",
      updateSuccess: "Patient updated successfully",
      updateSuccessDialogMessage:
        "Patient details have successfully been updated.",
      editPatientInformation:
        "You are about to edit the patient information for {{givenName}} {{familyName}}. Information should only be edited if the details are inaccurate. If you need to do a new form for a different patient <a href='{{patientSearchRoute}}'>click here</a>. Patient information will not be updated on any signed forms or form parts.",
      editPatientConfirmation:
        "You are about to edit the patient information for {{givenName}} {{familyName}}. Please ensure you have entered the correct details. Patient information will not be updated on any signed forms or form parts.",
    },
    patientSearch: {
      title: "Search Patient",
      mergePatientTitle:
        "Search for patient you want to merge this record into ",
      usePhoneticMatch: "Enable phonetic/soundex match",
      searchByNhsNumber: "Search by NHS number",
      searchByDemographics: "Search by Demographics",
      checkNhsNumberLabel: "I have the NHS Number",
      textFieldNhsLabel: "NHS Number",
      textFieldNhsInfo: "An NHS Number is a 10-digit number",
      phoneticTooltipText:
        "These are results which are different to what you have searched for but are similar or sound the same.",
      tabs: {
        labelOne: "Search eMHA",
        labelTwo: "Search Rio",
      },
      givenName: "Enter patient's forename",
      givenNameRequired: "Enter patient's forename (required)",
      familyName: "Enter patient's surname (required)",
      postalCode: "Enter patient's postcode",
      dateOfBirth: "Enter patient's date of birth",
      radioDob: "Do you have patient's date of birth?",
      radioDobRequired: "Do you have patient's date of birth? (required)",
      dropdownDateRangeLabel: "Enter approximate age range",
      patientCard: {
        nhsNumber: "NHS Number",
        dateOfBirth: "DOB",
        formControlLabel: "Select",
      },
      dateRange: {
        underAge18: "Under age 18",
        age18To39: "Age 18 to 39",
        age40To59: "Age 40 to 59",
        age60To79: "Age 60 to 79",
        overAge80: "Over age 80",
        unknown: "UNKNOWN",
      },
      searchReasons: {
        popupDialogTitle: "Provide reason for access",
        popupDialogBody:
          "You may not have a current associated care relationship with this patient, please confirm the patient details and provide a valid reason for accessing this record. Please note, all access will be recorded.",
        dropdownLabel: "Select reason",
        errorStates: {
          noPatientSelected: "No patient selected",
          selectAReason: "Please select a reason",
          provideReasonDescription: "Please provide a reason description",
        },
        otherReasonTextfieldLabel: "Reason description",
        searchReasonsValues: {
          emergencyOnCall: "Emergency/On-Call",
          adminAddDetails: "Admin/Add Details",
          referral: "Referral",
          newEpisodeOfCare: "New Episode of Care/New Form Context",
          auditInvestigationComplaint: "Audit/Investigation/Complaint",
          technicalSupport: "Technical Support",
          other: "Other",
        },
      },
      patientSearchResult: {
        title: "Search Result",
      },
      patientCreate: {
        title: "Create Patient",
        subtitle: "Create patient with demographics",
        familyNameAndAddress: {
          nameLabel: "Enter patient's surname*",
          addressLabel: "Enter patient's home address*",
        },
        givenName: "Enter patient's forename*",
        isDOBlabel: "Do you have patient's date of birth*",
        dateOfBirth: "Enter patient's date of birth",
      },
      patientSearchResultBanner: {
        uniqueMatch: {
          title: "Patient match",
          body1:
            "A patient has been found with the details above. Select the correct patient and click continue. \n",
          body2:
            "If this is not the correct patient, clear the search and perform a new search with different data or create a new patient.",
          patientMergeBody2:
            "If this is not the correct patient, clear the search and perform a new search with different data.",
        },
        multipleMatches: {
          title: "Multiple matches",
          body1:
            "Multiple patients have been found with the details above. Select the correct patient and click continue. \n",
          body2:
            "If this is not the correct patient, clear the search and perform a new search with different date or create a new patient.",
          patientMergeBody2:
            "If this is not the correct patient, clear the search and perform a new search with different date.",
        },
        noMatches: {
          title: "No match",
          body: "No patients have been found with the details above. Clear the search and perform a new search with different data or create a new patient.",
          patientMergeBody:
            "No patients have been found with the details above. Clear the search and perform a new search with different data.",
        },
        unknown: {
          title: "Unknown error",
          body: "Something went wrong searching. The Thalamos support team have been informed. Please try again later.",
        },
      },
      stepper: {
        searchPatient: "Search Patient",
        selectPatient: "Select Patient",
        newForm: "New Form",
        merge: "Merge Patient",
      },
    },
    patientSearchV2: {
      banner: {
        createWarning:
          "Please search for existing patients to avoid duplication, you can create a new patient record once you have searched. ",
      },
    },
    userProfile: {
      title: "Profile",
      subtitle: "View and manage your profile details",
      accreditationsSectionTitle: "Professions and registrations",
      noTeamsOrOrganisationsLabel: "No teams or organisations",
      formAccreditationsLabel: "Accreditations",
      profRegDropdown: {
        label:
          "Please select the professional bodies you are registered with (if any)",
        options: {
          gmc: "General Medical Council (GMC)",
          nmc: "Nursing and Midwifery Council (PIN)",
          hcpc: "Health & care professions council (HCPC)",
          swe: "Social Work England (SWE)",
        },
      },
      defaultMembership: "Default membership",
      mhApprovalsDropdown: {
        label: "Please select the roles you are approved for (if any)",
        options: {
          amhp: "Approved Mental Health Profressional (AMHP)",
          ac: "Approved Clinician or Responsible Clinician",
          s12: "Section 12 Approved",
        },
        info: {
          ac: "Approved Clinician Status is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be an Approved Clinician. If you are unsure whether you are an Approved Clinician, check with your mental health law team.",
          s12: "Section 12(2) is a specific approval under the Mental Health Act. You must have completed the relevant training and be approved by your regional approvals panel in order to be Section 12(2) approved. If you are unsure whether you are Section 12(2) approved, check with your mental health law team.",
        },
      },
      section12Approved: "Section 12 Approved",
      approvedClinician: "Are you an approved clinician?",
      approvedClinicianExpiryDate:
        "Enter approved clinician approval expiry date",
      localAuthorityFormLabel: "Enter the local authority you are approved by",
      gmcNumberLabel: "Enter General Medical Council (GMC) registration number",
      pinNumberLabel: "Enter Nursing and Midwifery Council (NMC) PIN number",
      hcpcNumberLabel:
        "Enter Health & Care Professions Council (HCPC) registration number",
      socialWorkerNumberLabel:
        "Enter Social Work England (SWE) registration number",
    },
    onBoarding: {
      formTitle: "Confirm Details",
      subtitle: "Enter professional details",
      dropdown: {
        label: "Select occupation(s)",
      },
      confirmOccupation: {
        formTitle: "Confirm occupation",
        gmcNumber: "GMC Number",
        isSection12Approved: "Are you Section 12 approved",
        section12ExpiryDate: "Enter section 12 approval expiry date",
        isApprovedClinician: "Are you an approved clinician",
        approvedClinicianExpiryDate: "Enter approved clinician expiry date",
      },
    },
    formDraftFlow: {
      standardFlow: {
        stepOne: "Complete form",
        stepTwo: "Sign form",
        stepThree: "Send form",
      },
    },
    notifications: {
      patientLabel: "Patient",
    },
    dashboard: {
      popupDialog: {
        warningMessage:
          "You are about to download personal identifiable date. Please explain why and enter the details of your manager who will be notified once you confirm. Please do not include any patient identifiable data in your reasoning. ",
      },
    },
    admissionFormPage: {
      bannerTitle: "Amend period expired",
      amendPeriodExpired72Hours:
        "The 72-hour rectification period has now expired and the form cannot be amended under Section 15, if there is an identified error after this time period; please discuss with you Mental Health Act administration team.",
      amendPeriodExpired14Day:
        "The 14-day rectification period has now expired and the form cannot be amended under Section 15, if there is an identified error after this time period; please discuss with you Mental Health Act administration team.",
      scrutiniseAdmissionBannerTitle:
        "Mark administrative scrutiny of this admission complete",
    },
    formUpload: {
      linkingFormsWarningBannerTitle:
        "You are about to select a from that has been uploaded, please make sure the medical recommendations are made by two different doctors.",
      firstPractitionerTitle: "First practitioner",
      secondPractitionerTitle: "Second practitioner",
      certifyThatCheck:
        "I certify that this is a true and exact reproduction of the original form, is of sufficient quality to allow readability of all information and is relevant to the care for this patient",
      confirmLabel: "Please check to confirm",
      confirmLabelAriaLabel: "checkbox for confirmation",
      previewLabel: "Preview Form Label",
      successMessageTitle: "Form uploaded successfully",
      successMessage: "Form has successfully been uploaded",
      warningBannerMessage:
        "Are you uploading a word document? Please save the document as a PDF before you upload, word files cannot be uploaded to the system (open the document, click save as and select PDF or download as PDF).",
      mixedFileDetectedErrorMessage:
        "Your upload contains a mix of file types. Please upload files of the same type for processing. Ensure all files are either PDFs or images.",
      uploadLabelSubtext:
        "You can upload exactly one PDF file or multiple images (JPG/PNG/GIF/WEBP format). Maximum file size is 5MB.",
      formDetailsLabel: "Confirm form details",
      uploadingLabel: "Uploading...",
      selectedFormLabel: "Select the form you are uploading",
    },
    patientStateDisplay: {
      whatIsMHAStatus: "What is MHA status?",
      mhaStatusExplained:
        "The MHA status captures the current MHA status of the patient and may automatically update when a new form is completed. The status refers to in-hospital MHA detentions, CTOs and discharges.",
      informationFrom: "Where is this information from?",
      informationFromExplained:
        "The status, expiry, renewals/extensions and organisation displayed are either automatically populated from a Thalamos form or have been manually input by an administrator. They reflect the most recent change on the eMHA system. To see more details, hover over the information icons in the banner. If non-Thalamos forms have been used, or if the patient has been moved without manual updates, the status may not be accurate.",
      contactLegalTeam:
        "Please contact your local MHA Legal Team if the status is inaccurate or not reflecting the care for this patient.",
    },
    policeIncidentFormPage: {
      synchroniseLoadingMessage: "Synchronising with police system...",
      formTitles: {
        policeIncident: "1. Police Incident Form",
        requestPolicePresence: "2. Request Police Presence",
        ongoingPolicePresence: "Do you need ongoing police presence?",
        sectionOutcome: "3. Section 136 Outcome Form",
      },
      notPoliceRequiredDialog: {
        title: "Confirm no police presence required",
        response: "Your response will be shared back with the officer",
        policeConfirm:
          "Please note, if circumstances change you must speak directly with police to confirm whether they are able to remain",
      },
      ongoingPolicePresenceDialog: {
        title: "Request ongoing police presence",
        warningBannerBody1:
          "Ongoing police presence is not guaranteed but subject to officers agreement and local policy and procedure",
        warningBannerBody2:
          "Please speak to the officer present if there is a change in circumstance",
      },
      toastNotifications: {
        policePresenceRequested: "Police presence requested",
        policePresenceNotRequested: "Police presence not required",
      },
    },
  },
};
