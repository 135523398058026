import {
  FormContextData,
  formIsCompleted,
  formIsInProgress,
  PatientTimelineResponse,
} from "@aspire/common";
import { Box, Card, CircularProgress, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { api, apiHooks } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { FormContextPdfViewer } from "../helpers/FormContextPdfViewer.js";
import { ParticipantsList } from "../helpers/ParticipantsList.js";

type OpenRequestPolicePresencePopup = "yes" | "no" | null;

function SynchroniseIncidentDialog({
  open,
  onClose,
  formContext,
  reloadFormContext,
}: {
  open: boolean;
  onClose: () => void;
  formContext: FormContextData;
  reloadFormContext: () => void;
}) {
  const [{ loading, data, response }] =
    apiHooks.vision.synchroniseIncidentForFormContext(formContext.id);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!loading) {
      if (response?.status === 204) {
        onClose();
      } else if (response?.status === 200) {
        reloadFormContext();
        onClose();
      } else {
        renderErrorToast({ message: "Failed to synchronise incident" });
      }
    }
  }, [loading, data, response]);

  return (
    <PopupDialog open={open} onClose={onClose} title="Synchronise Incident">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 4,
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {t("pages.policeIncidentFormPage.synchroniseLoadingMessage")}
        <CircularProgress />
      </Box>
    </PopupDialog>
  );
}

export function PoliceIncidentFormPage({
  reloadFormContext,
  formContext,
  patientTimeline,
  reloadPatientTimeline,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  patientTimeline: PatientTimelineResponse | null;
  reloadPatientTimeline: () => void;
}) {
  const policeForm = formContext.forms.find(
    (f) => f.formTemplate.id === "mha-136",
  )!;
  const [syncDialogOpen, setSyncDialogOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const [openRequestPolicePresencePopup, setOpenRequestPolicePresencePopup] =
    useState<OpenRequestPolicePresencePopup>(null);

  const nhsNumber = patientTimeline?.patient.nhsNumber;

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Box
        data-testid="form-context:police-incident"
        data-formcontextid={formContext.id}
      >
        {syncDialogOpen && (
          <SynchroniseIncidentDialog
            open={syncDialogOpen}
            onClose={() => {
              setSyncDialogOpen(false);
              setPdfViewFormId(policeForm.id);
            }}
            formContext={formContext}
            reloadFormContext={reloadFormContext}
          />
        )}
        {pdfViewFormId && (
          <FormContextPdfViewer
            inModal={true}
            forms={formContext.forms
              .filter((f) => formIsCompleted(f) || formIsInProgress(f))
              .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1))}
            setFormId={setPdfViewFormId}
            formId={pdfViewFormId}
          />
        )}

        <Box sx={{ mb: 3 }}>
          {patientTimeline && (
            <PatientBanner
              patient={formContext.patient}
              nhsNumber={nhsNumber ?? undefined}
              disableSticky
              isHorizontalLineHidden
              patientTimeline={patientTimeline}
              reloadPatientTimeline={reloadPatientTimeline}
            />
          )}
        </Box>

        <Stack sx={{ display: "flex" }}>
          <Card
            sx={{ width: "100%", padding: "2rem", mb: "2rem" }}
            onClick={() => setPdfViewFormId(policeForm.id)}
          >
            <Typography variant="h5" gutterBottom>
              {t("pages.policeIncidentFormPage.formTitles.policeIncident")}
            </Typography>
          </Card>

          <Card sx={{ width: "100%", padding: "2rem", mb: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              {t(
                "pages.policeIncidentFormPage.formTitles.requestPolicePresence",
              )}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {t(
                "pages.policeIncidentFormPage.formTitles.ongoingPolicePresence",
              )}
            </Typography>
            <Box sx={{ display: "flex", gap: "2rem", mt: "0.5rem" }}>
              <Button
                label={t("common.no")}
                variant="outlined"
                color="inherit"
                onClick={() => setOpenRequestPolicePresencePopup("no")}
              />
              <Button
                label={t("common.yes")}
                variant="outlined"
                color="inherit"
                onClick={() => setOpenRequestPolicePresencePopup("yes")}
              />
            </Box>
          </Card>

          <Card
            sx={{ width: "100%", padding: "2rem", mb: "2rem" }}
            onClick={() => alert("Not implemented")}
          >
            <Typography variant="h5" gutterBottom>
              {t("pages.policeIncidentFormPage.formTitles.sectionOutcome")}
            </Typography>
          </Card>
        </Stack>

        <ParticipantsList formContext={formContext} />
      </Box>
      {openRequestPolicePresencePopup === "yes" && (
        <RequestPolicePresencePopupDialog
          setOpenRequestPolicePresencePopup={setOpenRequestPolicePresencePopup}
          requestPolicePresenceCall={async () => {
            setIsSubmitting(true);
            if (!formContext.id) return;
            const result = await api.vision.requestPolicePresence(
              formContext.id,
              { label: "yes", value: "yes" },
            );

            if (result.status === 204) {
              setIsSubmitting(false);
              setOpenRequestPolicePresencePopup(null);
              reloadFormContext();
              renderSuccessToast({
                message: t(
                  "pages.policeIncidentFormPage.toastNotifications.policePresenceRequested",
                ),
              });
            } else {
              // @ts-expect-error - data is "unknown"
              const errorMessage = result.data?.reason || "Unknown error";
              renderErrorToast({ message: errorMessage });
              setIsSubmitting(false);
            }
          }}
        />
      )}
      {openRequestPolicePresencePopup === "no" && (
        <NotPolicePresencePopupDialog
          setOpenRequestPolicePresencePopup={setOpenRequestPolicePresencePopup}
          requestPolicePresenceCall={async () => {
            setIsSubmitting(true);
            if (!formContext.id) return;
            const result = await api.vision.requestPolicePresence(
              formContext.id,
              { label: "no", value: "no" },
            );
            if (result.status === 204) {
              setIsSubmitting(false);
              setOpenRequestPolicePresencePopup(null);
              reloadFormContext();
              renderSuccessToast({
                message: t(
                  "pages.policeIncidentFormPage.toastNotifications.policePresenceNotRequested",
                ),
              });
            } else {
              // @ts-expect-error - data is "unknown"
              const errorMessage = result.data?.reason || "Unknown error";
              renderErrorToast({ message: errorMessage });
              setIsSubmitting(false);
            }
          }}
        />
      )}
    </>
  );
}

function NotPolicePresencePopupDialog({
  setOpenRequestPolicePresencePopup,
  requestPolicePresenceCall,
}: {
  setOpenRequestPolicePresencePopup: (
    value: OpenRequestPolicePresencePopup,
  ) => void;
  requestPolicePresenceCall: () => void;
}) {
  const { t } = useTranslation();
  return (
    <PopupDialog
      open={true}
      onClose={() => setOpenRequestPolicePresencePopup(null)}
    >
      <PopupDialogTitle
        titleText={t(
          "pages.policeIncidentFormPage.notPoliceRequiredDialog.title",
        )}
        closeDialog={() => setOpenRequestPolicePresencePopup(null)}
      />
      <Box
        sx={{
          display: "flex",
          p: 2,
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Typography>
          {t("pages.policeIncidentFormPage.notPoliceRequiredDialog.response")}
        </Typography>
        <Typography>
          {t(
            "pages.policeIncidentFormPage.notPoliceRequiredDialog.policeConfirm",
          )}
        </Typography>
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            label="Close"
            testId="close-button"
            onClick={() => setOpenRequestPolicePresencePopup(null)}
            variant="outlined"
          />
          <Button
            label="Confirm"
            testId="confirm-button"
            onClick={() => requestPolicePresenceCall()}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}

function RequestPolicePresencePopupDialog({
  setOpenRequestPolicePresencePopup,
  requestPolicePresenceCall,
}: {
  setOpenRequestPolicePresencePopup: (
    value: OpenRequestPolicePresencePopup,
  ) => void;
  requestPolicePresenceCall: () => void;
}) {
  const { t } = useTranslation();
  return (
    <PopupDialog
      open={true}
      onClose={() => setOpenRequestPolicePresencePopup(null)}
      title="Request Police Presence"
    >
      <PopupDialogTitle
        titleText={t(
          "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.title",
        )}
        closeDialog={() => setOpenRequestPolicePresencePopup(null)}
      />
      <Box
        sx={{ display: "flex", p: 2, flexDirection: "column", gap: "1.5rem" }}
      >
        <Banner
          bannerType={BannerList.WARNING}
          body={[
            t(
              "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.warningBannerBody1",
            ),
            t(
              "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.warningBannerBody2",
            ),
          ]}
        />
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            label={t("buttonLabels.close")}
            testId="close-button"
            onClick={() => setOpenRequestPolicePresencePopup(null)}
            variant="outlined"
          />
          <Button
            label={t("buttonLabels.confirm")}
            testId="confirm-button"
            type="submit"
            onClick={() => requestPolicePresenceCall()}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
