import { Stack } from "@mui/material";
import { validateNhsNumber } from "@thalamos/common";
import { Form, Formik, FormikConfig } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InferType, object, string } from "yup";
import { HelperText } from "~/components/design-system/index.js";
import { TextboxFormField } from "~/components/form/TextboxFormField.js";
import { FieldProps } from "../FieldProps.js";

export const validationSchema = object({
  nhsNumber: string()
    .transform((value) => value.replace(/\s/g, "")) // Removes whitespace
    .matches(/[0-9]{10}/, "Enter a 10-digit NHS Number")
    .test("nhsNumber", "Invalid NHS Number", (value) =>
      value ? validateNhsNumber(value) : false,
    )
    .default(""),
});
export type ValidationSchema = InferType<typeof validationSchema>;

export type CollectCriteriaNhsNumberProps = Pick<
  FormikConfig<ValidationSchema>,
  "onSubmit" | "children"
> & { disabled?: boolean };

export const CollectCriteriaNhsNumber = ({
  onSubmit,
  children,
  disabled,
}: CollectCriteriaNhsNumberProps) => {
  const { t } = useTranslation();

  // Apply the transformations on the schema to the submitted data
  const scopedOnSubmit = useCallback<
    FormikConfig<ValidationSchema>["onSubmit"]
  >(
    (values, formikHelpers) =>
      onSubmit(validationSchema.cast(values), formikHelpers),
    [onSubmit],
  );

  return (
    <Formik<ValidationSchema>
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={validationSchema.getDefault()}
      onSubmit={scopedOnSubmit}
    >
      {(formikProps) => {
        const fieldProps: FieldProps<ValidationSchema> = {
          ...formikProps,
          validationSchema: validationSchema,
          context: {},
        };

        return (
          <Form>
            <Stack>
              <TextboxFormField
                field={{
                  disabled: disabled,
                  type: "textbox",
                  field: "nhsNumber",
                  label: t("pages.patientSearch.textFieldNhsLabel"),
                }}
                fieldProps={fieldProps}
              />
              <HelperText subtext={t("pages.patientSearch.textFieldNhsInfo")} />
            </Stack>

            {
              // Extra children which will be added to the form.
              // Useful for injecting a submit button for testing.
              typeof children === "function" ? children(formikProps) : children
            }
          </Form>
        );
      }}
    </Formik>
  );
};
