// This page just shows the patient search reasoning and then
// redirects you on somewhere else (patient/form context/review page)

import { CircularProgress, Stack } from "@mui/material";
import { useDialogs } from "@toolpad/core/useDialogs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { api } from "~/api.js";
import { renderErrorToast } from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";
import { SearchReasoningDialog } from "../PatientSearchV2/SearchReasoningDialog.js";

export type PatientSearchReasoningPageProps = {};

export function PatientSearchReasoningPage(_: PatientSearchReasoningPageProps) {
  const { patientId } = useParams();
  const [searchParams] = useSearchParams();
  const dialogs = useDialogs();

  const redirectTo = searchParams.get("redirectTo");

  const navigate = useNavigate();

  const redirect = redirectTo
    ? redirectTo
    : routeFns.patientHome(patientId ?? "");

  useEffect(() => {
    if (!patientId) {
      navigate(routeFns.home());
    } else {
      dialogs
        .open(SearchReasoningDialog)
        .then(async (response) => {
          if (response.type === "cancelled") {
            navigate(routeFns.home(), { replace: true });
          }
          if (response.type === "confirmed") {
            const submitResponse =
              await api.patients.saveSearchReasoningToSession({
                selectedPatientId: patientId,

                reason: response.reasoning.label,
                reasonDescription:
                  response.reasoning.reason === "other"
                    ? response.reasoning.description
                    : undefined,
              });

            if (submitResponse.status === 204) {
              navigate(redirect, { replace: true });
            } else {
              renderErrorToast({
                message: "Failed to record search reason",
              });
            }
          }
        })
        .catch((e) => {
          renderErrorToast({
            message: "Failed to record search reason: " + e.message,
          });
          navigate(routeFns.home(), { replace: true });
        });
    }
  }, [patientId]);

  return (
    patientId && (
      <Stack
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <CircularProgress />
      </Stack>
    )
  );
}
