import {
  EnhancedPatientDemographicsAlert,
  EnhancedPatientSuggestedMerge,
} from "@aspire/common";
import { Link } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NhsNumberSchema } from "@thalamos/common";
import dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { DefaultPageProps } from "../defaultProps.js";

export type DemographicsManagerHomePageProps = DefaultPageProps;

function SuggestedMergeRow(props: {
  merge: EnhancedPatientSuggestedMerge;
  reloadAlerts: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{dayjs(props.merge.created).format("DD/MM/YYYY")}</TableCell>
      <TableCell>{props.merge.type}</TableCell>
      <TableCell>
        {NhsNumberSchema.catch(t("common.unknown")).parse(
          props.merge.sourcePatient.nhsNumber,
        )}
      </TableCell>
      <TableCell>
        {props.merge.sourcePatient.name.given.toUpperCase()},{" "}
        {props.merge.sourcePatient.name.family}
      </TableCell>
      <TableCell>
        {props.merge.sourcePatient.dateOfBirth
          ? dayjs(props.merge.sourcePatient.dateOfBirth).format("DD/MM/YYYY")
          : "Unknown"}
      </TableCell>
      <TableCell>
        {NhsNumberSchema.catch(t("common.unknown")).parse(
          props.merge.targetPatient.nhsNumber,
        )}
      </TableCell>
      <TableCell>
        {props.merge.targetPatient.name.given.toUpperCase()},{" "}
        {props.merge.targetPatient.name.family}
      </TableCell>
      <TableCell>
        {props.merge.targetPatient.dateOfBirth
          ? dayjs(props.merge.targetPatient.dateOfBirth).format("DD/MM/YYYY")
          : "Unknown"}
      </TableCell>
      <TableCell>
        <Button
          label="Go to Merge"
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(
              routeFns.patientMergeConfirmation(
                props.merge.sourcePatientId,
                props.merge.targetPatientId,
              ),
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
}

function SuggestedMergesTable(props: {
  merges: EnhancedPatientSuggestedMerge[];
  reloadAlerts: () => void;
}) {
  if (props.merges.length === 0) {
    return <Typography>No suggested merges</Typography>;
  }

  return (
    <Stack gap="1rem">
      <Typography sx={{ fontWeight: "bold" }}>
        Suggested Patient Merges
      </Typography>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "background.lightGray" }}>
            <TableCell>Created Date</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Source Patient NHS Number</TableCell>
            <TableCell>Source Patient Name</TableCell>
            <TableCell>Source Patient Date of Birth</TableCell>
            <TableCell>Target Patient NHS Number</TableCell>
            <TableCell>Target Patient Name</TableCell>
            <TableCell>Target Patient Date of Birth</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.merges
            .sort((a, b) => (a.created > b.created ? 1 : -1))
            .map((merge) => (
              <SuggestedMergeRow
                merge={merge}
                reloadAlerts={props.reloadAlerts}
              />
            ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

function DemographicsAlertsRow(props: {
  alert: EnhancedPatientDemographicsAlert;
  reloadAlerts: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { alert } = props;

  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  return (
    <TableRow>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      <TableCell>
        <IconButton
          onClick={() => {
            navigate(routeFns.patientHome(alert.patientId));
          }}
        >
          <Link />
        </IconButton>
      </TableCell>
      <TableCell>{dayjs(alert.created).format("DD/MM/YYYY")}</TableCell>
      <TableCell>
        {alert.type === "invalidated" ? "Invalidated NHS number" : "Unknown"}
      </TableCell>
      <TableCell>
        {NhsNumberSchema.catch(t("common.unknown")).parse(alert.nhsNumber)}
      </TableCell>
      <TableCell>
        {alert.patient.name.given.toUpperCase()}, {alert.patient.name.family}
      </TableCell>
      <TableCell>
        {alert.patient.dateOfBirth
          ? dayjs(alert.patient.dateOfBirth).format("DD/MM/YYYY")
          : "Unknown"}
      </TableCell>
      <TableCell sx={{ display: "flex", gap: "0.5rem" }}>
        <Button
          color="inherit"
          variant="contained"
          label="Merge Patient"
          onClick={() => {
            navigate(routeFns.patientMergeSearch(alert.patientId));
          }}
        />
        <Button
          label="Clear Patient Alerts"
          variant="contained"
          color="primary"
          onClick={async () => {
            setConfirmFn({
              message:
                "Are you sure you want to clear alerts for this patient? They will also be cleared automatically if the patient is merged with another.",
              confirmFn: async () => {
                const res = await api.patientDemographics.clearAlertsForPatient(
                  alert.patientId,
                );

                if (res.status !== 204) {
                  renderErrorToast({ message: "Failed to clear alerts" });
                } else {
                  renderSuccessToast({ message: "Alerts cleared" });
                  props.reloadAlerts();
                }
              },
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
}

function PatientDemographicsAlertsTable(props: {
  alerts: EnhancedPatientDemographicsAlert[];
  reloadAlerts: () => void;
}) {
  if (props.alerts.length === 0) {
    return <Typography>No demographics alerts</Typography>;
  }

  return (
    <Stack gap="1rem">
      <Typography sx={{ fontWeight: "bold" }}>
        Patient Demographics Alerts
      </Typography>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "background.lightGray" }}>
            <TableCell></TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Patient NHS Number</TableCell>
            <TableCell>Patient Name</TableCell>
            <TableCell>Patient Date of Birth</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.alerts
            .sort((a, b) => (a.created > b.created ? 1 : -1))
            .map((alert) => (
              <DemographicsAlertsRow
                alert={alert}
                reloadAlerts={props.reloadAlerts}
              />
            ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

export function DemographicsManagerHomePage(
  props: DemographicsManagerHomePageProps,
) {
  const loggedInUserContext = React.useContext(LoggedInUserContext);

  const [{ data: alerts, loading: alertsLoading }, reloadAlerts] =
    apiHooks.patientDemographics.getAlerts();

  return alertsLoading ? (
    <CircularProgress />
  ) : (
    <Stack>
      <Typography variant="h5">
        Demographics Management for{" "}
        {loggedInUserContext?.user.sessionContext?.dataControllerName}
      </Typography>
      <Card sx={{ mt: "2rem", padding: "1rem" }}>
        <SuggestedMergesTable
          merges={alerts!.suggestedMerges}
          reloadAlerts={reloadAlerts}
        />
      </Card>
      <Card sx={{ mt: "2rem", padding: "1rem" }}>
        <PatientDemographicsAlertsTable
          alerts={alerts!.alerts}
          reloadAlerts={reloadAlerts}
        />
      </Card>
    </Stack>
  );
}
