import { OrganisationMembership, TeamMembership } from "@aspire/common";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export function triggerDownload(dataUri: string, filename: string) {
  var element = document.createElement("a");
  element.setAttribute("href", dataUri);
  element.setAttribute("download", filename);

  document.body.appendChild(element);

  // onClick property
  element.click();

  document.body.removeChild(element);
}

/*
  Simply using atob doesn't work for decoding base64 strings in the browser with UTF8.
  This function is a workaround for that.
 */
export function decodeBase64(base64: string) {
  const text = atob(base64);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder(); // default is utf-8
  return decoder.decode(bytes);
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Success handler
    reader.onload = () => {
      const base64 = reader.result;
      if (typeof base64 === "string") {
        resolve(base64);
      } else {
        reject("Failed to convert blob to base64");
      }
    };

    // Failure handler
    reader.onerror = () => {
      reject("Failed to convert blob to base64");
    };
    reader.readAsDataURL(blob);
  });
}

export function ukLocalFormatDate(utcValue: string) {
  return dayjs.utc(utcValue).tz("Europe/London").format("DD/MM/YYYY");
}

export function ukLocalFormatDateTime(utcValue: string) {
  return dayjs.utc(utcValue).tz("Europe/London").format("DD-MM-YYYY HH:mm");
}

function roleUnknown(_role: never): string {
  throw Error(`Unknown role`);
}

export function getRoleLabel(
  role: OrganisationMembership["role"] | TeamMembership["role"],
): string {
  switch (role) {
    case "manager":
      return "Admin";
    case "member":
      return "Member";
    case "demographics-manager":
      return "Demographics Admin";
    case "analytics-viewer":
      return "Analytics Viewer";
    default:
      return roleUnknown(role);
  }
}
