import { Box, CircularProgress, Typography } from "@mui/material";
import { Formik } from "formik";
import { object, string } from "yup";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  FormLabel,
  HelperText,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";

const pdsConfigurationSchema = object({
  apiKey: string().min(5).required(),
  jwtKid: string().min(3).required(),
  privateKey: string().min(5).required(),
  endpoint: string().min(5).required(),
});

function PdsConfigurationInner({
  pdsConfiguration: { apiKey, jwtKid, endpoint },
  reload,
}: {
  pdsConfiguration: { apiKey: string; jwtKid: string; endpoint: string };
  reload: () => void;
}) {
  return (
    <Formik<{
      apiKey: string;
      jwtKid: string;
      endpoint: string;
      privateKey: string;
    }>
      initialValues={{ apiKey, jwtKid, endpoint, privateKey: "" }}
      validationSchema={pdsConfigurationSchema}
      onSubmit={async (values) => {
        const result = await api.configuration.updatePdsConfiguration({
          apiKey: values.apiKey,
          jwtKid: values.jwtKid,
          endpoint: values.endpoint,
          privateKey: values.privateKey,
        });

        if (result.status === 204) {
          renderSuccessToast({ message: "PDS configuration updated" });
          reload();
        } else {
          renderErrorToast({ message: "Failed to update PDS configuration" });
        }
      }}
    >
      {(formikData) => {
        const { values, setValues, errors, touched, submitForm, dirty } =
          formikData;

        return (
          <Box
            sx={{
              maxWidth: "600px",
              marginBottom: "2em",
              gap: "1em",
              mt: "2em",
            }}
          >
            <Typography variant="h5">PDS Configuration</Typography>
            <Box sx={{ mt: "2em" }}>
              <FormLabel
                label={"Endpoint (*)"}
                error={touched.endpoint && !!errors.endpoint}
              />
              <TextField
                name={"endpoint"}
                useFullWidth={true}
                value={values.endpoint}
                onChange={(e) => {
                  setValues({
                    ...values,
                    endpoint: e,
                  });
                }}
              />
              {errors.endpoint && touched.endpoint && (
                <Box sx={{ mb: 4 }}>
                  <HelperText errorMessage={errors.endpoint as string} />
                </Box>
              )}
            </Box>

            <Box sx={{ mt: "0.5em" }}>
              <FormLabel
                label={"API Key (*)"}
                error={touched.apiKey && !!errors.apiKey}
              />
              <TextField
                name={"apiKey"}
                useFullWidth={true}
                value={values.apiKey}
                onChange={(e) => {
                  setValues({
                    ...values,
                    apiKey: e,
                  });
                }}
              />
              {errors.apiKey && touched.apiKey && (
                <Box sx={{ mb: 4 }}>
                  <HelperText errorMessage={errors.apiKey as string} />
                </Box>
              )}
            </Box>

            <Box sx={{ mt: "0.5em" }}>
              <FormLabel
                label={"JWT KID (*)"}
                error={touched.jwtKid && !!errors.jwtKid}
              />
              <TextField
                name={"jwtKid"}
                useFullWidth={true}
                value={values.jwtKid}
                onChange={(e) => {
                  setValues({
                    ...values,
                    jwtKid: e,
                  });
                }}
              />
              {errors.jwtKid && touched.jwtKid && (
                <Box sx={{ mb: 4 }}>
                  <HelperText errorMessage={errors.jwtKid as string} />
                </Box>
              )}
            </Box>

            <Box sx={{ mt: "0.5em" }}>
              <FormLabel
                label={"Private Key - OBFUSCATED (*)"}
                error={touched.privateKey && !!errors.privateKey}
              />
              <TextField
                name={"privateKey"}
                useFullWidth={true}
                multiline={true}
                rows="10"
                value={values.privateKey}
                onChange={(e) => {
                  setValues({
                    ...values,
                    privateKey: e,
                  });
                }}
              />
              {errors.privateKey && touched.privateKey && (
                <Box sx={{ mb: 4 }}>
                  <HelperText errorMessage={errors.privateKey as string} />
                </Box>
              )}
            </Box>

            <Box sx={{ mt: "0.5em" }}>
              <Button onClick={submitForm} label="Submit" type="submit" />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}

export function PdsConfiguration() {
  const [{ data: pdsConfiguration, loading: pdsConfigurationLoading }, reload] =
    apiHooks.configuration.getPdsConfiguration();

  return pdsConfigurationLoading ? (
    <CircularProgress />
  ) : (
    <PdsConfigurationInner
      pdsConfiguration={pdsConfiguration!}
      reload={reload}
    />
  );
}
