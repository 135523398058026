import {
  COMPLETED_FORM_STATUSES,
  ExtendedThalamosUser,
  ExternalPatientLinkEnhanced,
  FormContextData,
  FormContextMiniForm,
  getBaseFormTemplate,
  getLatestVersion,
  PatientTimelineResponse,
  UUID,
} from "@aspire/common";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { uniq } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuOptionsType } from "~/components/design-system/index.js";
import { useExternalPatientLinks } from "~/hooks/ExternalPatientLink/useExternalPatientLinks.js";
import { api, apiHooks } from "../../../api.js";
import { useGetFileNotes } from "../../../hooks/apiCalls.js";
import { routeFns } from "../../../routes.js";
import { triggerDownload } from "../../../util.js";
import { FormRow } from "../helpers/FormRow.js";
import {
  admissionFormContextLogic,
  canContinueAdmissionForm,
  canStartAdmissionForm,
} from "../helpers/formContextLogic.js";

export function AdmissionFormRow({
  form,
  formContext,
  createDraftFn,
  user,
  setPdfViewFormId,
  showDeleteConfirmation,
  showRequestAmendDialog,
  lastMerged,
  patientTimeline,
}: {
  form: FormContextMiniForm;
  formContext: Omit<FormContextData, "patient">;
  createDraftFn: () => Promise<void>;
  user: ExtendedThalamosUser;
  showDeleteConfirmation?: (formDraftId: UUID) => void;
  showRequestAmendDialog?: (formId: UUID, formTemplateId: string) => void;
  setPdfViewFormId?: (formId: string | null) => void;
  lastMerged?: string | undefined;
  patientTimeline: PatientTimelineResponse | null;
}) {
  const {
    canLaunchApplication,
    canLaunchMedicalRecommendation,
    mayUploadFormsToExternalSystem,
    formData,
    completedRecordOfDetention,
  } = admissionFormContextLogic(formContext, user);

  const {
    myFormWorkItem,
    canLaunchDraft,
    canRequestAmend,
    formNeedsMoreSignatures,
    isFormFinalised,
    isAmend,
    draftIdOnNextPart,
  } = formData.get(form.id) ?? {};

  const canUploadFormToExternalSystem =
    mayUploadFormsToExternalSystem &&
    COMPLETED_FORM_STATUSES.includes(form.status);

  const navigate = useNavigate();

  const template = getBaseFormTemplate(form)!;
  const latestVersion = getLatestVersion(form);
  const genericFormData =
    latestVersion.data.length > 0
      ? template!.extractGenericData(latestVersion.data)
      : { admissions: {} };
  const latestVersionSigned = latestVersion.signatures.length > 0;
  const anyVersionSigned = form.versions.some((v) => v.signatures.length > 0);

  const { fileNotes, reloadFileNotes } = useGetFileNotes(form.id, user);

  const hasFileNotes = fileNotes && fileNotes.length > 0;

  useEffect(() => {
    reloadFileNotes();
  }, [formContext, form.id]);

  const currentUserIsAssigned =
    formContext.activeTeamworkWorkItem?.assignedUserId === user.id;

  const canUserContinue = canContinueAdmissionForm(
    draftIdOnNextPart!,
    currentUserIsAssigned,
    completedRecordOfDetention,
    isAmend,
  );

  const externalLinks = useExternalPatientLinks({
    patientId: formContext.patientId,
  });

  const uploadToRioExternalLink:
    | ExternalPatientLinkEnhanced
    | null
    | undefined = useMemo(() => {
    if (canUploadFormToExternalSystem === false) {
      return null;
    }

    if (externalLinks.externalPatientLinksLoading === true) {
      return undefined;
    }

    const candidateLinks = (
      externalLinks.externalPatientLinks?.externalLinks ?? []
    ).filter(
      (externalPatientLink) => externalPatientLink.externalSystemType === "rio",
    );

    // The lowest number is the highest priority (i.e. 1 is higher priority than 2)
    const highestProirityLink = candidateLinks
      .filter((externalPatientLink) => externalPatientLink.canPush)
      .sort(
        (a, b) =>
          a.priorityWithinExternalSystem - b.priorityWithinExternalSystem,
      )?.[0];

    return highestProirityLink ?? null;
  }, [
    canUploadFormToExternalSystem,
    externalLinks.externalPatientLinks?.externalLinks,
    externalLinks.externalPatientLinksLoading,
  ]);

  const [uploadedToRioExternalLink, setUploadedToRioExternalLink] = useState<
    { uploaded: false } | { uploaded: true; date: string }
  >({ uploaded: false });

  const [{ data: externalPatientFormPushEvent }] =
    apiHooks.forms.getExternalPatientFormPushEvent(formContext.id, form.id);

  const shouldUploadToRioExternalLink =
    uploadToRioExternalLink !== undefined && uploadToRioExternalLink !== null;

  const externalPatientFormPushEventCreated =
    externalPatientFormPushEvent?.created;

  useEffect(() => {
    if (shouldUploadToRioExternalLink && externalPatientFormPushEventCreated) {
      setUploadedToRioExternalLink({
        uploaded: true,
        date: externalPatientFormPushEventCreated!,
      });
    }
  }, [externalPatientFormPushEventCreated, shouldUploadToRioExternalLink]);

  const menuOptions: MenuOptionsType[] = [
    ...(latestVersionSigned
      ? [
          {
            icon: "download",
            onClick: async () => {
              const res = await api.forms.getPdf(form.id, false);
              const dataUri = `data:application/pdf;base64,${res.data}`;
              triggerDownload(dataUri, "form.pdf");
            },
            name: "Download",
            disabled: false,
          },
        ]
      : []),
    ...(latestVersionSigned && setPdfViewFormId
      ? [
          {
            icon: "view",
            onClick: async () => {
              setPdfViewFormId(form.id);
            },
            name: "View PDF",
            disabled: false,
          },
        ]
      : []),
    ...(latestVersionSigned && uploadToRioExternalLink && patientTimeline
      ? [
          {
            name: "Upload to Rio",
            icon: uploadedToRioExternalLink.uploaded ? "uploadDone" : "upload",
            tooltip: uploadedToRioExternalLink.uploaded
              ? [
                  "Uploaded to Rio",
                  dayjs(uploadedToRioExternalLink.date).format(
                    "DD/MM/YYYY HH:mm",
                  ),
                ]
              : undefined,
            onClick: async () => {
              if (uploadToRioExternalLink) {
                await externalLinks.uploadFormPdfViaExternalPatientLink(
                  uploadToRioExternalLink,
                  [form.id],
                  { ...formContext, patient: patientTimeline.patient },
                );
              }
            },
            disabled: uploadToRioExternalLink === undefined,
          },
        ]
      : []),
    ...(formNeedsMoreSignatures && formContext.activeTeamworkWorkItem
      ? [
          {
            icon: "transfer",
            link: routeFns.formContextPageSendRequest(
              formContext.id,
              formContext.patientId,
              {
                formId: form.id,
                requestType: "complete",
                formPart: "1",
              },
            ),
            name: "Send",
            disabled: false,
          },
        ]
      : []),
    ...(!anyVersionSigned && currentUserIsAssigned && !!showDeleteConfirmation
      ? [
          {
            name: "Delete",
            onClick: () => showDeleteConfirmation(form.id),
            icon: "delete",
            disabled: false,
          },
        ]
      : []),
    ...(canRequestAmend && showRequestAmendDialog
      ? [
          {
            name: "Request Amend",
            onClick: () =>
              showRequestAmendDialog(form.id, form.formTemplate.id),
            icon: "edit",
            disabled: false,
          },
        ]
      : []),
    ...(canUserContinue
      ? [
          {
            icon: "edit",
            link: routeFns.formDraftsComplete(
              draftIdOnNextPart!,
              formContext.patientId,
            ),
            name: "Continue",
            disabled: false,
          },
        ]
      : []),
    ...(canStartAdmissionForm(
      canLaunchDraft,
      myFormWorkItem,
      currentUserIsAssigned,
    )
      ? [
          {
            icon: "edit",
            onClick: async () => {
              await createDraftFn();
            },
            name: "Start",
            disabled: false,
          },
        ]
      : []),
    ...(draftIdOnNextPart &&
    (canLaunchMedicalRecommendation || canLaunchApplication)
      ? [
          {
            icon: "cancel",
            link: routeFns.formContextReasonForNotProceedingDialog(
              formContext.id,
              formContext.patientId,
            ),
            name: canLaunchApplication ? "No application" : "No recommendation",
            disabled: false,
          },
        ]
      : []),
    ...(currentUserIsAssigned &&
    !isFormFinalised &&
    user.sessionContext?.teamType === "mha"
      ? [
          {
            icon: "add",
            onClick: () => {
              return navigate(
                routeFns.formContextAddFileNoteDialog(
                  formContext.id,
                  formContext.patientId,
                  { formId: form.id },
                ),
              );
            },
            name: "Add file note",
            disabled: false,
          },
        ]
      : []),
    ...(currentUserIsAssigned &&
    hasFileNotes &&
    !isFormFinalised &&
    user.sessionContext?.teamType === "mha"
      ? [
          {
            icon: "delete",
            onClick: () => {
              return navigate(
                routeFns.formContextDeleteFileNoteDialog(
                  formContext.id,
                  formContext.patientId,
                  { formId: form.id },
                ),
              );
            },
            name: "Delete file note(s)",
            disabled: false,
          },
        ]
      : []),
  ];

  const theme = useTheme();

  /**
   * Summary Function:
   * This function determines the status of an admission form based on its type and associated work items.
   * If the form type is "admission," it checks the status of the latest version and matching work items.
   * Returns an object with status text and color for display purposes.
   */
  const summary = () => {
    if (formContext.type === "admission") {
      // Since the form may be from a different form context post-linking, attempt to find it
      // on the patient timeline first of all as we are more likely to find the amend work item in
      // that scenario. We back off to using the form context work items if we can't find it on the timeline. (ASP-1605)
      const workItems =
        patientTimeline?.inProgressWork?.admissions?.find(
          (a) => a.id === form.formContextId,
        )?.workItems || formContext.workItems;

      const matchingWorkItems = workItems.find(
        (w: any) => w.formId === form.id && w.requestType === "amend",
      );

      if (
        latestVersion.status === "complete" &&
        matchingWorkItems?.status === "accepted"
      ) {
        return {
          statusText: "Amend completed",
          colour: theme.palette.statuses.teal,
        };
      }
      if (matchingWorkItems?.status === "pending") {
        return {
          statusText: "Amend requested",
          colour: theme.palette.statuses.yellow,
        };
      }
      if (matchingWorkItems?.status === "accepted") {
        return {
          statusText: "Amend in progress",
          colour: theme.palette.statuses.yellow,
        };
      }
    }

    return;
  };

  const formChipData = genericFormData?.admissions || {};
  let formDataChips: string[] = [];
  if ("applicationHospitalName" in formChipData) {
    const hospitalName = formChipData.applicationHospitalName;
    formDataChips.push(`Hospital Name: ${hospitalName}`);
  }
  if ("admissionHospitalName" in formChipData) {
    formDataChips.push(`Hospital Name: ${formChipData.admissionHospitalName}`);
  }
  if ("section12ApprovedStatus" in formChipData) {
    const s12status = formChipData.section12ApprovedStatus!.some((s) => s);
    formDataChips.push(`Section 12 Approved: ${s12status ? "Yes" : "No"}`);
  }
  if ("applicationHospitalName" in formChipData) {
    formDataChips.push(
      `Hospital Name: ${formChipData.applicationHospitalName}`,
    );
  }
  if ("lastExaminedDate" in formChipData) {
    formDataChips.push(
      ...(formChipData.lastExaminedDate ?? []).map(
        (d) => `Last Examined Date: ${dayjs(d).format("DD/MM/YYYY")}`,
      ),
    );
  }

  const isFormManualUploaded = form.source === "manual-upload";

  const signedByText =
    latestVersion.signatures.length > 0 &&
    `${
      isFormManualUploaded
        ? `Form uploaded by ${latestVersion.signatures[0].userName!}`
        : `Signed by ${latestVersion.signatures
            .map((s, index) => s.userName!)
            .join(", ")}`
    } `;

  return (
    <>
      <externalLinks.Component />
      <FormRow
        menuOptions={menuOptions}
        formDataChips={uniq(formDataChips)}
        heading={`Section ${template!.section} - ${template!.description}`}
        subHeading={`${signedByText || ""}Last updated: ${dayjs
          .utc(form.updated)
          .fromNow()}`}
        summary={summary()}
        lastMerged={lastMerged}
        formUploadedAt={isFormManualUploaded ? form.created : undefined}
      />
    </>
  );
}
