import { ExternalMappedDemographics, Patient } from "@aspire/common";

export type SystemType = "eMHA" | "Other";

export type Choice = {
  system: SystemType;
  type: string;
  value: string | null;
};

export type PatientRecords = {
  eMHA: Patient | undefined;
  Other: ExternalMappedDemographics | undefined;
};

export const doAllValuesMatch = (choices: Choice[]) => {
  const definedChoices = choices.filter(
    (choice) =>
      (choice.value ?? undefined !== undefined) ||
      ["dob", "deathNotificationStatus"].includes(choice.type),
  );

  const allChoicesMatch = definedChoices.every(
    (choice) => choice.value === definedChoices[0].value,
  );

  return allChoicesMatch;
};

/**
 * Find the obvious choice.
 * Perhaps there is only one.
 * Or perhaps they are all the same.
 */
export const obviousChoice = (choices: Choice[]) => {
  return doAllValuesMatch(choices)
    ? choices.filter(
        (choice) =>
          choice.value !== null ||
          ["dob", "deathNotificationStatus"].includes(choice.type),
      )?.[0].system
    : undefined;
};
