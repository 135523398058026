import React, { useMemo, useState } from "react";

import {
  ExternalPatientLinkEnhanced,
  FormContextData,
  FormContextMiniForm,
  SelectedFormsObject,
  formIsApplication,
  formIsRecordOfDetention,
  getBaseFormTemplate,
} from "@aspire/common";
import { Box } from "@mui/material";
import {
  Banner,
  BannerList,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/FormFooter.js";
import { FormCheckboxRow } from "~/pages/FormDraft/AdmissionFormCheckboxRow.js";
import { FormContextPdfViewer } from "~/pages/FormProgressPage/helpers/FormContextPdfViewer.js";

export const SelectFormsUploadDialog = ({
  open,
  onClose,
  forms,
  formContext,
  uploadFormPdfViaExternalPatientLink,
  uploadToRioExternalLink,
}: {
  open: boolean;
  onClose: () => void;
  forms: FormContextMiniForm[];
  formContext: FormContextData;
  uploadFormPdfViaExternalPatientLink: (
    externalLink: ExternalPatientLinkEnhanced,
    formIds: string[],
    formContext: FormContextData,
  ) => Promise<void>;
  uploadToRioExternalLink: ExternalPatientLinkEnhanced;
}) => {
  const [selectedForms, setSelectedForms] = useState<SelectedFormsObject[]>([]);
  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const h3 = useMemo(
    () => forms.find((f) => formIsRecordOfDetention(f.formTemplate)),
    [forms],
  );

  const applicationIds = useMemo(
    () =>
      forms.filter((f) => formIsApplication(f.formTemplate)).map((f) => f.id),
    [forms],
  );

  const h3NotSelected = useMemo(
    () => h3 && !selectedForms.some((s) => s.id.includes(h3.id)),
    [selectedForms],
  );

  const noApplicationSelected = useMemo(
    () =>
      applicationIds.length > 0 &&
      !selectedForms.some((s) => applicationIds.includes(s.id)),
    [selectedForms],
  );

  const moreThanOneApplicationSelected = useMemo(
    () =>
      applicationIds.length > 1 &&
      selectedForms.filter((s) => applicationIds.includes(s.id)).length > 1,
    [selectedForms],
  );

  const selectedSections = useMemo(
    () => selectedForms.map((s) => getBaseFormTemplate(s.templateId)?.section),
    [selectedForms],
  );

  const differentSectionsSelected = useMemo(
    () =>
      (selectedSections.includes("2") && selectedSections.includes("3")) ||
      (selectedSections.includes("3") && selectedSections.includes("4")),
    [selectedSections],
  );

  return (
    <PopupDialog open={open} maxWidth="lg">
      <PopupDialogTitle
        closeDialog={onClose}
        titleText="Select Forms"
      ></PopupDialogTitle>
      <Banner
        body="The forms will be uploaded in the order they are selected. For example, the form that is selected first, will show first."
        bannerType={BannerList.INFO}
        sx={{ mb: 2 }}
      />
      <Box>
        {forms.map((form) => {
          const checked = selectedForms.some((s) => s.id.includes(form.id));

          return (
            <FormCheckboxRow
              key={form.id}
              setSelectedForms={setSelectedForms}
              setPdfViewFormId={setPdfViewFormId}
              form={form}
              formContext={formContext}
              checked={checked}
              isMultiSelect={true}
              isSingleSelectCheckBox={false}
            />
          );
        })}
        {pdfViewFormId && (
          <FormContextPdfViewer
            inModal={true}
            forms={forms.filter((f) => f.id === pdfViewFormId)}
            setFormId={setPdfViewFormId}
            formId={pdfViewFormId}
          />
        )}
      </Box>
      {(h3NotSelected ||
        noApplicationSelected ||
        moreThanOneApplicationSelected ||
        differentSectionsSelected) &&
        selectedForms.length > 0 && (
          <Banner
            body="It looks like you have selected an unusual combination of forms, please make sure you have selected the correct forms."
            bannerType={BannerList.WARNING}
            sx={{ mb: 2 }}
          />
        )}
      <FormFooterSection
        onSave={async () => {
          await uploadFormPdfViaExternalPatientLink(
            uploadToRioExternalLink,
            selectedForms.map((s) => s.id),
            formContext,
          );
          onClose();
        }}
        onCancel={onClose}
        saveLabel="Continue"
        discardLabel="Close"
        disableSubmit={selectedForms.length === 0}
      ></FormFooterSection>
    </PopupDialog>
  );
};
