import {
  ExtendedThalamosUser,
  ExternalPatientLinkEnhanced,
  FormContextData,
  getLatestVersion,
} from "@aspire/common";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  calculateWorkItemStatus,
  FormTitle,
  MenuOptionsType,
  renderErrorToast,
} from "~/components/design-system/index.js";
import { Container, ExpandMore } from "~/components/layout/styleWrappers.js";
import { useExternalPatientLinks } from "~/hooks/ExternalPatientLink/useExternalPatientLinks.js";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage.js";
import { api } from "../../../api.js";
import { useGetFileNotes } from "../../../hooks/apiCalls.js";
import { routeFns } from "../../../routes.js";
import { triggerDownload } from "../../../util.js";
import { standaloneFormContextLogic } from "../helpers/formContextLogic.js";
import { FormRow } from "../helpers/FormRow.js";

export type StandaloneFormRowProps = {
  formContext: FormContextData;
  setPdfViewFormId: (formId: string | null) => void;
  user: ExtendedThalamosUser;
  showFinaliseConfirmation: (fullFormName: string) => void;
  showMhaStatusConfirmation: (fullFormName: string) => void;
  showDeleteConfirmation: () => void;
  lastMerged?: string | undefined;
};

export function StandaloneFormRow({
  formContext,
  setPdfViewFormId,
  user,
  showFinaliseConfirmation,
  showMhaStatusConfirmation,
  showDeleteConfirmation,
  lastMerged,
}: StandaloneFormRowProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const formDraftId = useMemo(() => v4(), []);

  const { t } = useTranslation();

  const externalLinks = useExternalPatientLinks({
    patientId: formContext.patientId,
  });

  const [showForm, setShowForm] = React.useState(true);

  const {
    fullFormName,
    draftIdOnNextPart,
    canLaunchDraft,
    form,
    formNeedsFinalise,
    canDownloadForm,
    canUploadFormToExternalSystem,
    canFinaliseForm,
    canSendForm,
    canDeleteForm,
    canContinueForm,
  } = standaloneFormContextLogic(formContext, user);

  const uploadToRioExternalLink:
    | ExternalPatientLinkEnhanced
    | null
    | undefined = useMemo(() => {
    if (canUploadFormToExternalSystem === false) {
      return null;
    }

    if (externalLinks.externalPatientLinksLoading === true) {
      return undefined;
    }

    const candidateLinks = (
      externalLinks.externalPatientLinks?.externalLinks ?? []
    ).filter(
      (externalPatientLink) => externalPatientLink.externalSystemType === "rio",
    );

    // The lowest number is the highest priority (i.e. 1 is higher priority than 2)
    const highestProirityLink = candidateLinks
      .filter((externalPatientLink) => externalPatientLink.canPush)
      .sort(
        (a, b) =>
          a.priorityWithinExternalSystem - b.priorityWithinExternalSystem,
      )?.[0];

    return highestProirityLink ?? null;
  }, [
    canUploadFormToExternalSystem,
    externalLinks.externalPatientLinks?.externalLinks,
    externalLinks.externalPatientLinksLoading,
  ]);

  const [uploadedToRioExternalLink, setUploadedToRioExternalLink] = useState<
    { uploaded: false } | { uploaded: true; date: string }
  >({ uploaded: false });

  useEffect(() => {
    const doEffect = async () => {
      if (uploadToRioExternalLink) {
        const result = await externalLinks.getExternalPatientFormPushEvent(
          uploadToRioExternalLink,
          formContext.id,
          form.id,
        );
        if (result !== null) {
          setUploadedToRioExternalLink({
            uploaded: true,
            date: result.created,
          });
        }
      }
    };

    doEffect();
  }, [
    externalLinks.externalPatientLinksLoading,
    externalLinks.getExternalPatientFormPushEvent,
    formContext.id,
    uploadToRioExternalLink,
  ]);

  const summary = calculateWorkItemStatus(theme, {
    formContextType: formContext.type,
    forms: formContext.forms,
    otherWorkItems: formContext.workItems,
  });

  const { fileNotes, reloadFileNotes } = useGetFileNotes(form.id, user);

  const hasFileNotes = Array.isArray(fileNotes) && fileNotes.length > 0;

  useEffect(() => {
    reloadFileNotes();
  }, [formContext, form.id]);

  const formHasMoreThanOneSignature =
    getLatestVersion(form).signatures?.length > 0;

  const subheading = `${
    formContext.type === "standalone" && formHasMoreThanOneSignature
      ? `Signed by: ${form.versions
          .at(-1)!
          .signatures.map((s) => s.userName)
          .join(", ")}.`
      : ""
  }  Last updated:
    ${dayjs.utc(formContext.lastFormUpdated || formContext.updated).fromNow()}`;

  const showEditMhaStatus = userCanEditMhaStatus(user);

  const menuOptions: MenuOptionsType[] = [
    ...(canDownloadForm
      ? [
          {
            icon: "view",
            onClick: async () => {
              setPdfViewFormId(form.id);
            },
            name: "View PDF",
            disabled: false,
          },
          {
            name: "Download",
            onClick: async () => {
              const result = await api.forms.getPdf(
                form.id,
                formContext.type === "admission",
              );
              if (result.status === 200) {
                const dataUri = `data:application/pdf;base64,${result.data}`;
                triggerDownload(dataUri, "form.pdf");
              }
            },
            icon: "download",
            disabled: false,
          },
        ]
      : []),
    ...(uploadToRioExternalLink !== null
      ? [
          {
            name: "Upload to Rio",
            icon: uploadedToRioExternalLink.uploaded ? "uploadDone" : "upload",
            tooltip: uploadedToRioExternalLink.uploaded
              ? [
                  "Uploaded to Rio",
                  dayjs(uploadedToRioExternalLink.date).format(
                    "DD/MM/YYYY HH:mm",
                  ),
                ]
              : undefined,
            onClick: async () => {
              if (uploadToRioExternalLink) {
                await externalLinks.uploadFormPdfViaExternalPatientLink(
                  uploadToRioExternalLink,
                  [form.id],
                  formContext,
                );
              }
            },
            disabled: uploadToRioExternalLink === undefined,
          },
        ]
      : []),
    ...(canDeleteForm
      ? [
          {
            name: "Delete",
            onClick: async () => {
              showDeleteConfirmation();
            },
            icon: "delete",
            disabled: false,
          },
        ]
      : []),
    ...(canLaunchDraft
      ? [
          {
            name: "Start",
            onClick: async () => {
              const result = await api.drafts.create(formDraftId, {
                formId: form.id,
              });
              if (result.status === 201) {
                navigate(
                  routeFns.formDraftsComplete(
                    formDraftId,
                    formContext.patientId,
                  ),
                );
              } else {
                renderErrorToast({ message: "Failed to create draft" });
              }
            },
            icon: "edit",
            disabled: false,
          },
        ]
      : []),
    ...(canContinueForm
      ? [
          {
            name: "Continue",
            link: routeFns.formDraftsComplete(
              draftIdOnNextPart!,
              formContext.patientId,
            ),
            icon: "edit",
            disabled: false,
          },
        ]
      : []),
    ...(canSendForm
      ? [
          {
            name: "Send",
            link: routeFns.formContextPageSendRequest(
              formContext.id,
              formContext.patientId,
              formNeedsFinalise
                ? { requestType: "finalise" }
                : {
                    requestType: "complete",
                    formId: form.id,
                    formPart: getLatestVersion(form).data.length,
                  },
            ),
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),
    ...(canFinaliseForm
      ? [
          {
            name: "Complete Scrutiny",
            onClick: () =>
              showEditMhaStatus
                ? showMhaStatusConfirmation(fullFormName ?? "")
                : showFinaliseConfirmation(fullFormName ?? ""),
            icon: "accept",
            disabled: false,
          },
        ]
      : []),
    ...(user.sessionContext?.teamType === "mha" &&
    form.status !== "finalised" &&
    formHasMoreThanOneSignature
      ? [
          {
            icon: "add",
            onClick: () => {
              return navigate(
                routeFns.formContextAddFileNoteDialog(
                  formContext.id,
                  formContext.patientId,
                  { formId: form.id },
                ),
              );
            },
            name: "Add file note",
            disabled: false,
          },
        ]
      : []),
    ...(user.sessionContext?.teamType === "mha" &&
    form.status !== "finalised" &&
    hasFileNotes
      ? [
          {
            icon: "delete",
            onClick: () => {
              return navigate(
                routeFns.formContextDeleteFileNoteDialog(
                  formContext.id,
                  formContext.patientId,
                  { formId: form.id },
                ),
              );
            },
            name: "Delete file note(s)",
            disabled: false,
          },
        ]
      : []),
  ];

  return (
    <Container addMarginBottom data-testid="standalone-form-row">
      <externalLinks.Component />
      <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
        <FormTitle
          subtitleText={t("common.form")}
          subTitleTextFontSize={2}
          hasContainerMarginBottom={false}
        />
        <ExpandMore
          role="button"
          isExpanded={showForm}
          onClick={() => setShowForm(!showForm)}
          aria-expanded={showForm}
          aria-label={t("components.cardExtended.expandMoreLabel")}
        >
          <ExpandMoreIcon sx={{ color: "primary.main" }} />
        </ExpandMore>
      </Box>
      {showForm && (
        <Box sx={{ mt: 2 }}>
          <FormRow
            menuOptions={menuOptions ?? []}
            heading={fullFormName ?? ""}
            subHeading={subheading}
            summary={summary}
            lastMerged={lastMerged}
          />
        </Box>
      )}
    </Container>
  );
}
