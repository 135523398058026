import {
  defaultConfiguration,
  ExtendedThalamosUser,
  isDemographicsManagerUser,
  isGuestUserSession,
  isManagerUser,
  isTeamManagerUser,
} from "@aspire/common";
import {
  Add,
  ArticleOutlined,
  ContactSupportOutlined,
  DashboardOutlined,
  GroupOutlined,
  Inventory2Outlined,
  LogoutSharp,
  MenuBookOutlined,
  NotificationsNoneRounded,
  Person2Outlined,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";
import React from "react";
import { config } from "../../../config.js";
import { routeFns } from "../../../routes.js";

type ThalamosMenuItems = {
  label: string;
  link?: string;
  onClick?: () => void;
  disabled?: boolean;
  icon: React.ReactNode;
  text: string;
  requiresAdminUser?: boolean;
  requiresTeamContext?: boolean;
  isDesktopOnly?: boolean;
  openNewTab?: boolean;
  miniText?: string;
};

export const menuData = (
  user: ExtendedThalamosUser | null,
): ThalamosMenuItems[] => {
  const isGuestUser = isGuestUserSession(user);

  const pathname = window.location.pathname;
  const regex = /\/patients\/([a-zA-Z0-9-]+)/;
  const match = pathname.match(regex);

  const isManagerRole = isManagerUser(user);

  const helpPageUrl =
    user?.sessionOrganisationConfiguration?.helpPageUrl ??
    defaultConfiguration.helpPageUrl;

  const supportPageUrl =
    user?.sessionOrganisationConfiguration?.supportPageUrl ??
    defaultConfiguration.supportPageUrl;

  const isTeamManager = isTeamManagerUser(user);

  const userSessionTeamId = user?.sessionContext?.teamId;

  const isDemographicsManager = isDemographicsManagerUser(user);

  const additionalMenuItems = [
    {
      label: "help guides",
      text: "Help Guides",
      link: helpPageUrl,
      openNewTab: true,
      icon: <MenuBookOutlined strokeWidth={0.8} stroke="white" />,
    },
    {
      label: "support",
      text: "Support",
      link: supportPageUrl,
      openNewTab: true,
      icon: <ContactSupportOutlined strokeWidth={0.8} stroke="white" />,
    },
    {
      label: "terms",
      text: "Terms & Conditions",
      link: "https://www.thalamos.co.uk/terms-and-conditions/",
      miniText: "T & C",
      openNewTab: true,
      icon: <ArticleOutlined strokeWidth={0.8} stroke="white" />,
    },
    {
      label: "logout",
      text: "Logout",
      isDesktopOnly: true,
      miniText: "Logout",
      icon: <LogoutSharp strokeWidth={0.8} stroke="white" />,
    },
  ];
  const demographicsManagerItems = isDemographicsManager
    ? [
        {
          label: "Demographics Management",
          text: "Demographics Management",
          link: routeFns.demographicsManagement(),
          icon: <Person2Outlined strokeWidth={0.8} stroke="white" />,
          requiresTeamContext: false,
        },
      ]
    : [];

  const items = isManagerRole
    ? [
        ...(!isTeamManager
          ? [
              {
                label: "Users",
                text: "Users",
                link: routeFns.managerUsersPage(),
                icon: <Person2Outlined strokeWidth={0.8} stroke="white" />,
                requiresTeamContext: false,
              },
            ]
          : []),

        ...(!isTeamManager
          ? [
              {
                label: "Teams",
                text: "Teams",
                link: routeFns.managerTeamsPage(),
                icon: <GroupOutlined strokeWidth={0.8} stroke="white" />,
                requiresTeamContext: false,
              },
            ]
          : userSessionTeamId
            ? [
                {
                  label: "Team",
                  text: "Team",
                  link: routeFns.managerTeamEdit(userSessionTeamId),
                  icon: <GroupOutlined strokeWidth={0.8} stroke="white" />,
                  requiresTeamContext: false,
                },
              ]
            : []),
        ...additionalMenuItems,
      ]
    : [
        ...demographicsManagerItems,
        {
          label: "admin",
          text: "Admin",
          miniText: "Admin",
          link: routeFns.adminUsersPage(),
          requiresAdminUser: true,
          icon: <SettingsOutlined strokeWidth={0.8} stroke="white" />,
        },
        {
          label: "caseload",
          text: "Caseload",
          link: routeFns.caseloadCaseload(),
          icon: <Inventory2Outlined strokeWidth={0.8} stroke="white" />,
          requiresTeamContext: true,
        },
        {
          label: "Patient",
          text: "Search Patient",
          link: routeFns.patientSearch(),
          icon: <Person2Outlined strokeWidth={0.8} stroke="white" />,
          requiresTeamContext: isDemographicsManager ? false : true,
          disabled: isGuestUser,
        },
        {
          label: "dashboard",
          link: routeFns.dashboard(),
          text: "Dashboard",
          icon: <DashboardOutlined strokeWidth={0.8} stroke="white" />,
          disabled: isGuestUser,
        },
        {
          label: "notifications",
          text: "Notifications",
          link: routeFns.notifications(),
          icon: <NotificationsNoneRounded strokeWidth={0.8} stroke="white" />,
          disabled: isGuestUser,
          requiresTeamContext: true,
        },
        ...additionalMenuItems,
      ];

  if (match) {
    const patientId = match[1];
    if (patientId && !isGuestUser) {
      items.unshift({
        label: "newForm",
        text: "New Form",
        link: routeFns.patientHome(match[1]),
        icon: <Add strokeWidth={0.8} stroke="white" />,
        requiresTeamContext: true,
      });
    }
  }

  return items;
};

export const mobileMenuData = (
  user: ExtendedThalamosUser | null,
): ThalamosMenuItems[] => {
  const isGuestUser = isGuestUserSession(user);

  return [
    ...menuData(user),
    {
      label: "settings",
      link: user ? routeFns.userProfilePage(user.id) : "/",
      text: "Settings",
      disabled: isGuestUser,
      icon: <Settings strokeWidth={0.8} stroke="white" />,
    },
    {
      label: "logout",
      link: `https://${config.auth0.domain}/v2/logout?client_id=${
        config.auth0.clientId
      }&returnTo=${encodeURIComponent(
        `${config.backendUrl}/api/oauth/logout`,
      )}`,
      text: "Logout",
      icon: <LogoutSharp strokeWidth={0.8} stroke="white" />,
    },
  ];
};
